@import '../variable';

.dashboard-card {
    .avatar-lg {
        height: 4.5rem;
        width: 4.5rem;
    }

    .avatar-title {
        align-items: center;
        color: #fff;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .bg-soft-warning {
        background-color: #ffb60040 !important;
    }

    .bg-soft-success {
        background-color: #5ad09240 !important;
    }

    .bg-soft-info {
        background-color: #006dff40 !important;
    }
}

.calendar-time {
    .bs-datepicker {
        display: flex;
        align-items: stretch;
        flex-flow: row wrap;
        background: #fff;
        box-shadow: none !important;
        position: relative;
        z-index: 1;
    }

    .bs-datepicker-body table td {
        color: #54708b;
        text-align: center;
        position: relative;
        padding: .2rem;
    }
}
