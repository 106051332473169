@import '../variable';

.app-auth-wrapper {
  background: #fff;
  height: 100vh;
}

.auth-background-col {
  position: relative;
}

.app-login .auth-background-holder {
  background: url(../../images/website/slider/two.webp) no-repeat center center;
  background-size: cover;
  height: 100vh;
  min-height: 100%;
}

.auth-background-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
}

.auth-background-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
}

.auth-background-overlay .overlay-content {
  background: rgba(37, 41, 48, 0.6);
  color: #fff;
}

.auth-background-overlay .overlay-title {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
}

.auth-background-overlay .overlay-content a {
  text-decoration: underline;
  color: #fff;
}

.app-auth-wrapper .app-auth-body {
  width: 360px;
}

.app-auth-wrapper .app-logo .logo-icon {
  width: 200px;
}

.app-auth-wrapper .auth-heading {
  font-size: 1.5rem;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  height: 2.5rem;
  border-color: #e7e9ed;
  color: #5d6778;
}

.app-btn-primary {
  background: #15a362;
  color: #fff;
  border-color: #15a362;
}

.auth-intro {
  font-size: 16px;
}

.back-home {
  border: 2px solid $primary;
  padding: 2px 8px;
  cursor: pointer;
  border-radius: 4px;

  em {
    font-size: 20px;

    &::before{
      font-weight: bold !important;
    }
  }

  &:hover {
    background-color: $primary;
    color: #fff;
    em {
      color: #fff;
    }
  }
}
