@import '../variable';

.h-100vh {
    height: 100vh;
}

.bg-overlay-gradient {
    background: #1b1f25cc;
    background: -webkit-linear-gradient(to right, #3c405a, #1b1f25cc);
    background: linear-gradient(to right, #3c405a, #1b1f25cc);
    opacity: 0.9;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.bg-overlay {
    background: #1b1f2580;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.btn {
    padding: 12px 26px;
}

.btn-rounded {
    border-radius: 30px !important;
}

.btn-custom {
    background-color: $primary;
    border: 2px solid $primary;
    color: #fff;
    transition: all 0.5s;
    border-radius: 5px;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.home-table-center {
    display: table-cell;
    vertical-align: middle;
}

.home-table {
    display: table;
    width: 100%;
    height: 100%;
}

.header_title {
    font-size: 40px;
    line-height: 1.2;
    text-transform: capitalize;
}

.header_subtitle {
    line-height: 1.8;
    font-size: 18px;
    max-width: 575px;
    color: #ffffffd1 !important;
}

.header_btn span {
    position: relative;
    top: 14px;
}

.bg-gradient {
    background: #1b1f25cc;
    background: -webkit-linear-gradient(to right, #3c405a, #1b1f25cc);
    background: linear-gradient(to right, #3c405a, #1b1f25cc);
}

// image slider
.bg-home-slider {
    position: relative;
}

.bg-home-slider .carousel-control-next,
.carousel-control-prev {
    width: 6%;
}

.bg-home-slider .carousel-item,
.bg-home-slider .carousel {
    width: 100%;
    height: 500px;
}

.bg-home-slider .carousel-item {
    background-position: center center !important;
    background-size: cover !important;
}

.carousel-control-prev > span,
.carousel-control-next > span {
    font-size: 50px;
}
