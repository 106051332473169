@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;600;700&display=swap");
@import '../variable';

:root {
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    transition: all 0.4s;
    border-radius: 20px;
    background-color: #e7e9ed;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    transition: all 0.4s;
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:horizontal:hover {
    cursor: pointer;
    height: 10px;
    width: 10px;
    border-radius: 40px;
}

html,
body {
    height: 100%;
    color: #5d6778 !important;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: 0.8rem;
    font-family: "Nunito", sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: #252930;
    font-weight: normal;
}

// date picker theme
.theme-dark-blue .bs-datepicker-head {
    background-color: $primary !important;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
    color: $primary !important;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $primary;
}
