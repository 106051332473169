@import "./variable";

// bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// fontawesome
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

@import "./_custom/resets";
@import "./_custom/dropdown";
@import "./settings";
@import "./_custom/tooltip";
@import "./_custom/popper";
@import "./_custom/dashboard";
@import "./_custom/table";
@import "./_custom/chat";
@import "./_custom/website";
@import "./_custom/auth";

.btn-primary {
  color: #ffffff;
}

.app {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #eeeeee4e;
  padding-top: 56px;
  overflow-x: hidden;
}

.app-header {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e7e9ed;
  height: 56px;
  background: #fff;
}

.app-header-inner {
  position: relative;
}

// @media styles
@media (min-width: 1200px) {
  .app-header-inner {
    margin-left: 250px;
  }
}

@media (min-width: 576px) {
  .app-notifications-dropdown .dropdown-menu {
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .app-wrapper {
    margin-left: 250px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 1199.98px) {
  .sidepanel-visible .sidepanel-drop {
    position: fixed;
    display: block;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    min-width: 100vw;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
  }
}

@media (max-width: 1199.98px) {
  .app-sidepanel {
    transition: all 0.4s ease-in-out;
    left: -400px;
  }
}

@media (max-width: 575.98px) {
  .search-mobile-trigger {
    cursor: pointer;
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
    opacity: 0.8;
  }
}

@media (max-width: 575.98px) {
  .app-search-box {
    display: none;
    position: absolute;
    left: 0;
    top: 56px;
    padding: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.4s ease-in-out;
  }
}

@media (max-width: 575.98px) {
  .app-search-box.is-visible {
    display: block;
  }
}

@media (max-width: 575.98px) {
  .app-search-box {
    display: none;
    position: absolute;
    left: 0;
    top: 56px;
    padding: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.4s ease-in-out;
  }
}

// @media style

// svg resets
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-11 {
  width: 0.6875em;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

// svg resets

// search
.app-search-form {
  position: relative;
  max-width: 600px;
}

.app-search-form .search-input {
  font-size: 0.875rem;
  border-radius: 0.25rem;
  padding-right: 3rem;
  padding-left: 1rem;
}

.app-search-form .search-btn {
  color: #828d9f;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 0;
  padding: 0.5rem 1rem;
}
//seacrh

// form-control
.form-control {
  padding: 0.3rem 1.5rem 0.3rem 0.9rem;
  border-color: #e7e9ed;
  color: #6c757d;
  font-size: 0.875rem;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

// form-control

// btn
.btn {
  font-weight: 600;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}

.app-btn-secondary {
  background: #fff;
  color: #5d6778;
  border: 1px solid #bcc1cb;
}

.app-card-account .item .btn-sm,
.app-card-account .item .btn-group-sm > .btn {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
}

// btn

// utilities
.app-utility-item {
  display: inline-block;

  & + .app-utility-item {
    margin-left: 1.5rem;
  }
}

.app-utility-item .dropdown-toggle {
  position: relative;
}

.app-utility-item > a {
  color: #5d6778;
}

.app-utility-item .icon {
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
}

.app-utility-item .icon-badge.icon-badge {
  background: #ec776c;
  border: 2px solid #fff;
}

.app-utility-item .icon-badge {
  display: flex;
  border-radius: 50%;
  position: absolute;
  right: -0.6rem;
  top: -0.6rem;
  width: 25px;
  height: 25px;
  font-size: 0.6rem;
  font-weight: bold;
  padding-top: 1px;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.app-user-dropdown img {
  width: 38px;
  height: 38px;
  z-index: -1;
  position: relative;
  background: #5d6778a8;
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.app-utility-item .dropdown-menu {
  font-size: 0.875rem;
  margin: 0;
}

.app-utility-item .dropdown-menu.show {
  top: 23px !important;
}

.app-notifications-dropdown .dropdown-menu .dropdown-menu-header {
  border-bottom: 1px solid #e7e9ed;
}

.app-notifications-dropdown .dropdown-menu .dropdown-menu-title {
  font-size: 1rem;
}

.app-notifications-dropdown .dropdown-menu .item {
  min-width: 250px;
  position: relative;
  border-bottom: 1px solid #e7e9ed;
}

.app-notifications-dropdown .dropdown-menu .profile-image {
  width: 36px;
  height: 36px;
}

.app-notifications-dropdown .dropdown-menu .info {
  padding-right: 80px;
  position: relative;
}

.app-notifications-dropdown .dropdown-menu .meta {
  color: #828d9f;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.75rem;
}

.app-notifications-dropdown .app-icon-holder {
  display: inline-block;
  background: #edfdf6;
  color: $primary;
  width: 36px;
  height: 36px;
  padding-top: 4px;
  font-size: 1rem;
  text-align: center;
  border-radius: 50%;
}

.app-notifications-dropdown .app-icon-holder.icon-holder-mono {
  background: #f5f6fe;
  color: #828d9f;
}

.app-notifications-dropdown .app-icon-holder svg {
  width: 20px;
  height: 20px;
}

.app-notifications-dropdown .dropdown-menu .link-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
}

// utilities

// sidebar panel
.app-sidepanel.sidepanel-visible {
  left: 0;
}

.app-sidepanel.sidepanel-hidden {
  left: -400px;
}

.app-sidepanel {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 0.875rem;
  border-right: 1px solid #e7e9ed;
}

.app-sidepanel .sidepanel-inner {
  position: relative;
  z-index: 10;
  background: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 15px;
  width: 250px;
  height: 100%;
}

.app-sidepanel .sidepanel-close {
  display: none;
  position: absolute;
  z-index: 20;
  right: -2rem;
  top: 0;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
}

.app-branding {
  height: 60px;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.app-branding .logo-icon {
  width: 36px;
  height: 36px;
}

.app-branding .logo-text {
  color: #252930;
  font-size: 1.25rem;
  font-weight: 600;
  vertical-align: middle;
}

.app-sidepanel .app-nav-main {
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.1) #e7e9ed;
  scrollbar-width: thin;
}

.app-nav {
  color: #5d6778;
  display: block;
  padding: 0;
  width: 100%;
}

.app-nav .app-menu {
  margin-bottom: 0;
}

.accordion {
  overflow-anchor: none;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.app-nav .nav-item {
  display: block;
  color: #5d6778;
  background: #fff;
}

.app-nav .nav-link {
  display: block;
  padding: 0.875rem 1rem;
  color: #6e768e;
  position: relative;
  display: block;
  padding-left: 3rem;
  border-left: 3px solid transparent;
}

.app-nav .nav-link.active {
  color: $primary;
  background: #ffffff;
  border-left: 3px solid $primary;
  font-weight: 500;
}

.app-nav .nav-icon {
  position: absolute;
  left: 1rem;
  top: 0.7rem;
}

.app-nav .nav-icon .bi {
  width: 1.25rem;
  height: 1.25rem;
}

.app-nav .submenu-toggle {
  transition: all 0.4s ease-in-out;
}

.app-nav .submenu-arrow {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  color: #828d9f;
}

.app-nav .submenu-arrow svg {
  width: 14px;
  height: 14px;
}

a.collapsed {
  .up {
    display: none !important;
  }
}

a:not(.collapsed) {
  .down {
    display: none !important;
  }
}

.collapse:not(.show) {
  display: none;
}

.app-nav .submenu-item {
  margin-bottom: 0;
  a {
    text-decoration: none;
  }
}

.app-nav .submenu-link {
  color: #5d6778;
  font-size: 0.875rem;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  padding-left: 1rem;
  position: relative;
  display: block;
  margin-bottom: 0;

  &.active {
    color: $primary;
    &::before {
      background-color: $primary;
    }
  }
}

.app-nav .submenu-link:before {
  background-color: #e7e9ed;
  content: " ";
  display: inline-block;
  height: inherit;
  left: 0;
  position: absolute;
  width: 2px;
  height: 100%;
  margin-top: -0.5rem;
}

.app-sidepanel-footer {
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e7e9ed;
  background: #f5f6fe;
}

.app-sidepanel-footer .app-nav-footer {
  margin-bottom: 0;
}
// sidebar panel

// notifications
.app-notification-panel.notification-panel-visible {
  right: 0;
}

.app-notification-panel.notification-panel-hidden {
  right: -400px;
}

.app-notification-panel {
  transition: all 0.4s ease-in-out;
  position: fixed;
  min-height: 100vh;
  height: 100%;
  top: 0;
  z-index: 1000;
  border-right: 1px solid #e7e9ed;
}

.app-notification-panel .notification-panel-inner {
  position: relative;
  background: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 15px;
  width: 400px;
  height: 100%;
  padding-top: 56px;
}

.notification-panel-visible .notification-panel-drop {
  position: fixed;
  display: block;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  min-width: 100vw;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.35);
}

.app-notification-panel .notification-panel-close {
  display: none;
  position: absolute;
  left: -2rem;
  top: 50px;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
}

.notification-panel-visible .notification-panel-close {
  display: block;
}

span.noti-dot {
  border: 4px solid #15a362;
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 24px;
  width: 24px;
  -webkit-animation: pulse 1s ease-out;
  -moz-animation: pulse 1s ease-out;
  animation: pulse 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
  opacity: 0;
}

span.noti-dot {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.6rem;
  border: 5px solid #ec776c;
  padding-top: 1px;
  background-color: #ec776c;
  text-align: center;
  color: #fff;
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }

  25% {
    -moz-transform: scale(0);
    opacity: 0.2;
  }

  50% {
    -moz-transform: scale(0.1);
    opacity: 0.4;
  }

  75% {
    -moz-transform: scale(0.5);
    opacity: 0.6;
  }

  100% {
    -moz-transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.4;
  }

  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.6;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

// notifications

// main content
.app-wrapper {
  transition: all 0.4s ease-in-out;
}

.app-page-title {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.app-card.border-left-decoration {
  border-left: 3px solid $primary;
}

.app-card {
  position: relative;
  background: #fff;
  border-radius: 0.25rem;

  .app-card-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e7e9ed;
  }

  .app-card-body {
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
  }
}

.app-card-basic {
  height: 100%;
}

.app-card .app-card-title {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.app-card .app-icon-holder {
  display: inline-block;
  background: #edfdf6;
  color: $primary;
  width: 50px;
  height: 50px;
  padding-top: 10px;
  font-size: 1rem;
  text-align: center;
  border-radius: 50%;
}

.app-card .app-icon-holder svg {
  width: 24px;
  height: 24px;
}

.app-btn-primary {
  background: $primary;
  color: #fff;
  border-color: $primary;
}

.app-card .btn-close {
  padding: 1rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1rem;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: 15px;
  text-align: center;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    no-repeat center center/1em auto;
  background-clip: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.25rem;
}

@media (max-width: 1199.98px) {
  .sidepanel-visible .sidepanel-close {
    display: inline-block;
  }
}

// card
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
  border: none !important;
  border-radius: 0.25rem;
}

.app-nav-tabs {
  background: #fff;
  padding: 0;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.app-nav-tabs .nav-link {
  color: #5d6778;
  font-size: 0.875rem;
  font-weight: bold;
}

.app-nav-tabs .nav-link.active {
  color: $primary;
  border-bottom: 2px solid $primary;
}

.app-card-orders-table .table {
  font-size: 0.875rem;
}

.app-card-orders-table .table .cell {
  border-color: #e7e9ed;
  color: #5d6778;
  vertical-align: middle;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.app-card-orders-table .cell span {
  display: inline-block;
}

.app-card-orders-table .cell .note {
  display: block;
  color: #828d9f;
  font-size: 0.75rem;
}

table.table td .avatar {
  margin: 0 5px 0 0;
}

.table-light {
  background-color: #f3f7f9;
  color: #343a40;
  border-color: #e0e4e7;
}

.table-centered td,
.table-centered th {
  vertical-align: middle !important;
  color: #6c757d;
}

.text-reset {
  color: inherit !important;
}

.avatar {
  background-color: #aaa;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  margin: 0 10px 0 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 38px;
  position: relative;
  white-space: nowrap;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2 a {
  color: #5d6778;
}

.dropdown.action-label {
  display: inline-block;
}

.action-label .btn-sm {
  padding: 5px;
}

.action-label > a {
  display: inline-block;
  min-width: 103px;
  color: #5d6778;
  font-size: 0.875rem;
}

.rounded,
.circle {
  border-radius: 500px;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.action-label i.caret {
  margin-left: 3px;
  margin-right: 0;
}

.action-label i {
  margin-right: 3px;
}

.action-label .dropdown-menu > li > a {
  padding: 3px 10px;
  color: #5d6778;
  text-decoration: none;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transform-origin: left top 0;
  box-shadow: inherit;
  background-color: #fff;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #5d6778;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: none !important;
}

.alertbg-success {
  background-color: $alertbg-success;
}

.alertbg-danger {
  background-color: $alertbg-danger;
}

.alertbg-info {
  background-color: $alertbg-info;
}

.alertbg-warning {
  background-color: $alertbg-warning;
}

td .action-btn {
  background-color: rgba(128, 128, 128, 0.096);
  padding: 7px 10px;
  border-radius: 50%;
  cursor: pointer;
}

// progress track
@keyframes ldio-p10lyfp1lwt {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-p10lyfp1lwt div {
  box-sizing: border-box !important;
}
.ldio-p10lyfp1lwt > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #0f7647 transparent #0f7647 transparent;
  animation: ldio-p10lyfp1lwt 1s linear infinite;
}

.ldio-p10lyfp1lwt > div:nth-child(2),
.ldio-p10lyfp1lwt > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-p10lyfp1lwt 1s linear infinite reverse;
}
.ldio-p10lyfp1lwt > div:nth-child(2) {
  border-color: transparent #0f7647 transparent #0f7647;
}
.ldio-p10lyfp1lwt > div:nth-child(3) {
  border-color: transparent;
}
.ldio-p10lyfp1lwt > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-p10lyfp1lwt > div:nth-child(3) div:before,
.ldio-p10lyfp1lwt > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #0f7647;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #0f7647;
}
.ldio-p10lyfp1lwt > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #0f7647;
}

.ldio-p10lyfp1lwt > div:nth-child(4) {
  border-color: transparent;
}
.ldio-p10lyfp1lwt > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-p10lyfp1lwt > div:nth-child(4) div:before,
.ldio-p10lyfp1lwt > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: #0f7647;
  border-radius: 50%;
  box-shadow: 0 46px 0 0 #0f7647;
}
.ldio-p10lyfp1lwt > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 #0f7647;
}
.loadingio-spinner-double-ring-et8w8v0s9an {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-p10lyfp1lwt {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-p10lyfp1lwt div {
  box-sizing: content-box;
}

.custom-alert-plus {
  padding: 0.3rem 1.25rem !important;
  border-radius: 2rem !important;
  max-width: 40%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 680px) {
  .custom-alert-plus {
    max-width: 100%;
  }
}

// progress track

// tinymce overrides
.tox-tinymce {
  border: 1px solid #e7e9ed;
  border-radius: 5px !important;
}

.link-disabled {
  color: #6e768e40 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}
