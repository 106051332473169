$primary: #5ac5b4ff;
$input-color: #5d6778;
$alertbg-success: #5ac5b4ff;
$alertbg-danger: #ec3e3e;
$alertbg-info: #61affe;
$alertbg-warning: #fca130;
$info: #17a2b8;
$warning: #ffc107;
$chat-text: #eff2f7;
$muted: #6c757d;
$white: #ffffff;

.badge-primary {
    color: white;
    background-color: #665ac5;
}

.badge-flagged {
    color: white;
    background-color: #05a5e3;
}
