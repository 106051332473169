@import './variable';

.menu-left {
    width: 240px;
    float: left;
    padding: 0 20px 20px 10px;
    position: relative;

    &:before {
        border-right: 5px solid #f5f6f8;
        content: "";
        position: absolute;
        top: 0;
        right: -15px;
        bottom: -1.5rem;
    }

    .menu-list {
        a {
            color: #6c757d;
            padding: 9px 10px;
            display: block;
            font-size: 15px;
            text-decoration: none;

            i {
                margin-right: 0.75rem !important;
            }

            &.active {
                border-radius: 4px;
                background-color: $primary;
                color: #fff;
                box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
            }
        }
    }
}

.body-right {
    margin: -1.5rem 0 -1.5rem 250px;
    border-left: 5px solid #f5f6f8;
    padding: 1.5rem 0 1.5rem 25px;
}

.search-bar {
    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    .form-control-light {
        background-color: #f3f7f9 !important;
        border-color: #f3f7f9 !important;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: calc(1.5em + 0.9rem + 2px);
        left: 13px;
        top: -2px;
        color: #98a6ad;
    }
}

.dropdown-menu {
    padding: 0.3rem;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    transition: 0.5;
    animation: ease-in-out;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    top: 100%;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.375rem 1.2rem;
    clear: both;
    font-weight: 400;
    color: #6c757d;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;

    i {
        font-size: 12px;
    }

    &:hover {
        background-color: #f2f6f8;
    }
}

.dropdown-menu i {
    display: inline-block;
}

.user-image {
    width: 100px;
    height: 100px;
    background: #5d6778d1;
    border-radius: 50%;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.permission-groups {
    border: 1px solid #e5e5e5;
    padding: 12px;
    border-radius: 4px;
    max-height: 400px;
    overflow: auto;
}

tr.select-user {
    td {
        border: none;
    }
    cursor: pointer;
    &:hover {
        background-color: #f3f7f98f;
    }

    &.active {
        background-color: $primary;
        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 8%) 0px 0.125rem 0.25rem !important;

        td {
            color: #fff !important;

            a {
                color: #fff !important;
            }
        }

        td:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .avatar {
            border: 1px white solid;
        }
    }

    a {
        text-decoration: none;
    }
}


.group {
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 20px;

    .group-label {
        position: absolute;
        top: -14px;
        background-color: white;
        color: $primary;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
    }
}