@use "sass:map";
@import "../variable";

// table resets
.table-card {
  position: relative;
  border-radius: 5px;
  background-color: #fff;
}

td.cell {
  vertical-align: middle;
}

th.cell {
  min-width: 150px;
  font-weight: 700;
  color: #6c757d;
  border-top: none;
  border-bottom: none;
}

th.cell .cell-border {
  font-weight: inherit;
  display: flex;
  justify-content: space-between;
  border-right: 2px solid #939494;
  margin: 0rem -0.7rem;
  padding: 0rem 0.7rem;
}

th:last-child.cell .cell-border {
  display: block;
  border-right: none;
}

th .column-filter {
  font-weight: inherit;
  color: inherit;
  em {
    font-size: 16px;
  }
}

th.odd,
td.odd {
  background-color: #d9fdd959;
}

th.even,
td.even {
  background-color: #ffd0d047;
}

.bordered-cell {
  border: 1px solid #dee2e6;
}

.bordered-cell-total {
  border: 1px solid $primary !important;
}

.table th {
  padding: 0.4rem 0.7rem;
}

.table th,
.table td {
  white-space: nowrap;
}

.table td {
  padding: 0.3rem 0.7rem;
}

.table tr {
  background: #fff;

  td {
    color: $muted;
  }
}

.table-sticky-column {
  .stick {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  }
}

// theming table
.themed-table {
  $theme-color: (
    "plain": (
      "color-solid": $primary,
      "color-faint": $white,
    ),
    "primary": (
      "color-solid": $primary,
      "color-faint": #f2f8fd,
    ),
    "danger": (
      "color-solid": #e44966,
      "color-faint": #fdf1f2,
    ),
    "success": (
      "color-solid": #2ecb84,
      "color-faint": #e4f8ef,
    ),
  );

  @each $color, $value in $theme-color {
    &.themed-table-#{$color} {
      tbody tr {
        background: #{map.get($value, "color-faint")};

        &:hover {
          background: #f9f9f9;
        }

        td {
          color: $muted;
        }

        &.active {
          background: #{map.get($value, "color-solid")} !important;
          td.stick {
            background: #{map.get($value, "color-solid")} !important;
          }
          td {
            color: #fff !important;
          }
        }
      }
    }
  }
}

.action-btn {
  padding: 1px 10px;
  &:hover {
    background: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
  }
}

// sticky first column

th.odd,
td.odd {
  background-color: #d9fdd959;
}

th.even,
td.even {
  background-color: #ffd0d047;
}

// study type row
table.table.themed-table tr.stat td h2 a {
  color: #f16d04f5 !important;
}

table.table.themed-table tr.stat .cell {
  color: #f16d04f5 !important;
}

table.table.themed-table tr.proirity td h2 a {
  color: #f10404f5 !important;
}

table.table.themed-table tr.proirity .cell {
  color: #f10404f5 !important;
}
