@import "../variable";

.chat-body {
    flex: 1 1 auto;
    background: #f5f6fe;
    border-radius: 4px;

    .chat-bg {
        padding: 1.5rem 1.5rem;
        // background: url("/assets/images/messenger-bg.png");
        background: #fff;
    }

    [data-simplebar] {
        position: relative;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
    }
    .conversation-list {
        list-style: none;
        padding: 0 15px;
    }

    .conversation-list li {
        margin-bottom: 20px;
    }

    .conversation-list .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;

        img {
            height: 42px;
            width: 42px;
        }

        .rounded {
            border-radius: 50% !important;
        }
    }

    .conversation-list .odd .chat-avatar {
        float: right !important;
    }

    .conversation-list .chat-avatar i {
        font-size: 12px;
        font-style: normal;
    }

    .conversation-list .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        border-radius: 10px;
        width: 70%;
    }

    .conversation-list .odd .conversation-text {
        float: right !important;
        margin-right: 12px;
        text-align: right;
        width: 70% !important;
    }

    .conversation-list .ctext-wrap {
        background: $chat-text;
        border-radius: 10px;
        display: inline-block;
        padding: 12px;
        position: relative;
        width: 100%;
        border-top-left-radius: 0px;
    }

    .conversation-list .odd .ctext-wrap {
        background-color: $chat-text;
        // color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
    }

    .conversation-list .ctext-wrap i {
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        position: relative;
    }

    .conversation-list .ctext-wrap p {
        margin: 0;
        padding-top: 3px;
        word-break: break-word;
    }

    .conversation-list .ctext-wrap:after {
        left: -10px;
        top: 0;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: $chat-text;
        border-width: 6px;
        margin-right: -1px;
        border-right-color: $chat-text;
    }

    .conversation-list .odd .ctext-wrap:after {
        border-color: transparent;
        border-left-color: $chat-text;
        border-top-color: $chat-text;
        right: -10px !important;
        left: auto;
    }

    .simplebar-wrapper {
        overflow: hidden;
        width: inherit;
        height: inherit;
        max-width: inherit;
        max-height: inherit;
    }

    .simplebar-height-auto-observer-wrapper {
        box-sizing: inherit !important;
        height: 100%;
        width: 100%;
        max-width: 1px;
        position: relative;
        float: left;
        max-height: 1px;
        overflow: hidden;
        z-index: -1;
        padding: 0;
        margin: 0;
        pointer-events: none;
        flex-grow: inherit;
        flex-shrink: 0;
        flex-basis: 0;
    }

    .simplebar-height-auto-observer {
        box-sizing: inherit;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 1000%;
        width: 1000%;
        min-height: 1px;
        min-width: 1px;
        overflow: hidden;
        pointer-events: none;
        z-index: -1;
    }

    .simplebar-mask {
        direction: inherit;
        position: absolute;
        overflow: hidden;
        padding: 0;
        margin: 0;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: auto !important;
        height: auto !important;
        z-index: 0;

        .simplebar-offset {
            direction: inherit !important;
            box-sizing: inherit !important;
            resize: none !important;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 0;
            margin: 0;
            -webkit-overflow-scrolling: touch;
        }

        .simplebar-content-wrapper {
            direction: inherit;
            box-sizing: border-box !important;
            position: relative;
            display: block;
            height: 100%;
            width: auto;
            visibility: visible;
            overflow: auto;
            max-width: 100%;
            max-height: 100%;
            scrollbar-width: none;
        }
    }

    .simplebar-placeholder {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        pointer-events: none;
    }

    .chat-input {
        box-shadow: 0 0 35px 0 rgb(0 0 0 / 15%);
    }

    .message-content.empty .no-messages {
        div {
            height: 100px;
            width: 100px;
            background: #fff;
            padding: 4px;
            border-radius: 50%;
            box-shadow: 0 0 35px 0 rgb(0 0 0 / 15%);
        }
        i {
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 48px;
            line-height: 100px;
        }
    }
}
