@mixin stagger-transition-delay($i, $initial-delay, $stagger-delay) {
    @while $i > 0 {
        &:nth-of-type(#{$i}) {
            transition-delay: ($initial-delay + $stagger-delay * $i);
        }
        $i: $i - 0.1;
    }
}

$cubic-bezier: cubic-bezier(0.19, 1, 0.22, 1);

.dropdown-menu {
    backface-visibility: hidden;
    opacity: 0;
    transform: scale(0);
    display: block;
    transition: opacity 0.2s linear, max-height 0.3s $cubic-bezier, transform 0s linear;
    @include stagger-transition-delay(0.9, 0.5s, 0.1s);
}
.dropdown-menu.show {
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.3s linear, max-height 2s $cubic-bezier, transform 0s linear;
}

.dropdown-menu {
    padding: 0.3rem;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
    font-size: 0.875rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9f0f4;
    border-radius: 0.25rem;

    .dropdown-item {
        color: #6c757d;
    }
}

.work-spaces {
    .dropdown-menu {
        @media (min-width: 992px) {
            div.p-lg-1 {
                padding: 0.375rem !important;
            }
        }

        .col-4:hover {
            background-color: #f3f7f9;
        }

        .dropdown-icon-item {
            border-radius: 3px;
            line-height: 34px;
            text-align: center;
            padding: 15px 0 9px;
            display: block;
            border: 1px solid transparent;
            color: #6c757d;
            width: 100%;

            img {
                height: 24px;
            }

            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.notification {
    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
    }

    .float-end {
        float: right !important;
    }

    .noti-scroll {
        max-height: 230px;

        .simplebar-wrapper {
            overflow: hidden auto;
            width: inherit;
            height: inherit;
            max-width: inherit;
            max-height: inherit;
        }
    }

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: #fff;
        }

        .notify-details {
            margin-bottom: 5px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #343a40;

            small {
                display: block;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }
}

#backend-filter {
    background: #f3f7f9;
    margin-right: 0;
    margin-left: 0;
    padding-top: 10px;
    border-radius: 4px;
}
