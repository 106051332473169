.footer_detail {
    background-color: #235c62ff;
}

.footer_detail .footer_logo {
    height: 60px;
}

.footer_detail .footer_menu li a {
    font-weight: 500;
    transition: all 0.5s;
}

.footer_detail .footer_menu li::after {
    content: "|";
    margin: 0 20px;
    color: #5ac5b4ff;
}

.footer_detail .footer_menu li:last-child:after {
    content: "";
}

.footer_detail .fot_social .social-icon {
    border: 2px solid #5ac5b4ff;
    border-radius: 50%;
    transition: all 0.5s;
    width: 42px;
    height: 42px;
    display: block;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
}